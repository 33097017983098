import React, { useRef, useEffect } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { Layout, Seo, CookieBanner } from '../components';
import HomeSection from '../sections/Home';
import AboutSection from '../sections/About';
import PortfolioSection from '../sections/Portfolio';
import ServicesSection from '../sections/Services';
import ContactSection from '../sections/Contact';
import '../styles/index.css';
import { useResponsive } from '../providers/responsive/Context';

const StyledLayout = styled(Layout)((props) => {
  if (props.responsive.isDesktopUp) {
    return {
      height: '100vh',
      '& .navbar': {
        position: 'static',
        top: 'unset',
      },
    };
  }
  return {};
});

const Index = ({ data, location }) => {
  const intl = useIntl();
  const responsive = useResponsive();
  const { isDesktopUp } = responsive;
  const siteTitle = data.site.siteMetadata?.title || `Title`;

  const sectionWrapper = useRef();
  let pos = sectionWrapper?.current?.scrollLeft;
  let moving = false;
  const speed = 120;
  const smooth = 12;

  function MouseWheelHandler(handledEvent) {
    let e = window.event || handledEvent;

    let delta = normalizeWheelDelta(e);
    pos += -delta * speed;
    pos = Math.max(
      0,
      Math.min(
        pos,
        sectionWrapper?.current?.scrollWidth -
          sectionWrapper?.current?.clientWidth,
      ),
    );

    if (!moving) update();
  }

  function normalizeWheelDelta(e) {
    if (e.detail) {
      if (e.wheelDelta)
        return (e.wheelDelta / e.detail / 40) * (e.detail > 0 ? 1 : -1);
      // Opera
      else return -e.detail / 3; // Firefox
    } else return e.wheelDelta / 120; // IE,Safari,Chrome
  }

  function update() {
    moving = true;

    let delta = (pos - sectionWrapper.current.scrollLeft) / smooth;

    sectionWrapper.current.scrollLeft += delta;

    if (Math.abs(delta) > 0.5) requestFrame(update);
    else moving = false;
  }

  const requestFrame = (() =>
    typeof window !== `undefined`
      ? window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.oRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        ((func) => {
          window.setTimeout(func, 1000 / 50);
        })
      : {})();

  useEffect(() => {
    sectionWrapper.current.addEventListener('wheel', (e) => {
      if (isDesktopUp) {
        e.preventDefault();
      }
    });

    return () => {
      sectionWrapper.current.removeEventListener('wheel', (e) => {
        if (isDesktopUp) {
          e.preventDefault();
        }
      });
    };
  }, [isDesktopUp]);

  return (
    <StyledLayout location={location} title={siteTitle} responsive={responsive}>
      <Seo title="Home" />

      <div
        ref={sectionWrapper}
        onWheel={MouseWheelHandler}
        className="section-wrapper"
        id="section-wrapper"
      >
        <HomeSection />
        <AboutSection title={intl.formatMessage({ id: 'about' })} />
        {/* <PortfolioSection title={intl.formatMessage({ id: 'portfolio' })} /> */}
        <ServicesSection title={intl.formatMessage({ id: 'services' })} />
        <ContactSection title={intl.formatMessage({ id: 'contact' })} />
      </div>

      <CookieBanner />
    </StyledLayout>
  );
};

export default Index;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
