import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import styled from '@emotion/styled';
import { PopupButton } from 'react-calendly';
import Lottie from 'lottie-react';

import { Section, Slogan, SVGWrapper, Grid } from '../components';

import StarSVG from '../images/star.svg';
import { useResponsive } from '../providers/responsive/Context';
import { useTheme } from '../providers/themes/Context';

const SloganCTAWrapper = styled.div((props) => ({
  paddingTop: props.responsive.isDesktopUp ? '9.219rem' : '3.915rem',
  borderRight: props.responsive.isDesktopUp
    ? `solid 1px ${props.theme.lines}`
    : 'none',
}));

const StyledSlogan = styled(Slogan)((props) => ({
  marginBottom: props.responsive.isDesktopUp ? '2.538rem' : '3.062rem',
}));

const ActionsContainer = styled.div((props) => ({
  display: 'flex',
  alignItems: props.responsive.isDesktopUp ? 'flex-start' : 'center',
  justifyContent: 'space-between',
  flexDirection: props.responsive.isDesktopUp ? 'column-reverse' : 'row',
}));

const StyledPopupButton = styled(PopupButton)((props) => ({
  backgroundColor: props.theme.buttons?.background,
  color: props.theme.buttons?.text,
  border: 'none',
  padding: '1rem 2.375rem',
  textTransform: 'uppercase',
  cursor: 'pointer',
  letterSpacing: 0.5,
  marginTop: props.responsive.isDesktopUp ? '2.384rem' : 0,
}));

const StylesSVGWrapper = styled(SVGWrapper)({
  maxWidth: '4.625rem',
});

const AnimationWrapper = styled.div((props) => ({
  height: props.responsive.isDesktopUp ? '100%' : 'inherit',
  backgroundColor: props.theme.primaryColor,
  marginBottom: props.responsive.isDesktopUp ? 0 : '3rem',
}));

const Home = ({ ...otherProps }) => {
  const responsive = useResponsive();
  const { theme } = useTheme();
  const intl = useIntl();

  return (
    <Section className="home-section" {...otherProps}>
      <Grid
        gap={responsive.isDesktopUp ? 0 : '3.351rem'}
        gridTemplateColumns={responsive.isDesktopUp ? '1fr 1fr' : '1fr'}
        styles={
          responsive.isDesktopUp ? { height: '100%', alignItems: 'center' } : {}
        }
      >
        <SloganCTAWrapper
          className="home-slogan"
          {...{ theme }}
          responsive={responsive}
        >
          <StyledSlogan responsive={responsive} />

          <ActionsContainer responsive={responsive}>
            <StyledPopupButton
              {...{ theme }}
              responsive={responsive}
              text={intl.formatMessage({ id: 'cta-call' })}
              url={intl.formatMessage({ id: 'calendly-url' })}
            />
            <StylesSVGWrapper color={theme.contrastColor}>
              <StarSVG />
            </StylesSVGWrapper>
          </ActionsContainer>
        </SloganCTAWrapper>

        <AnimationWrapper {...{ theme }} responsive={responsive}>
          <Lottie animationData={theme.beWildAnimation} />
        </AnimationWrapper>
      </Grid>
    </Section>
  );
};

export default Home;
