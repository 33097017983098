import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import styled from '@emotion/styled';
import {
  Section,
  Container,
  SVGWrapper,
  Grid,
  WordsSwitcher,
} from '../components';
import IconsSVG from '../images/icons-group.svg';
import { useResponsive } from '../providers/responsive/Context';
import { useTheme } from '../providers/themes/Context';

const CopyContainer = styled.div((props) => ({
  paddingBottom: '2.458rem',
  borderBottom: `solid 1px ${props.theme.lines}`,
}));

const Copy = styled.h3((props) => ({
  color: props.theme.text,
}));

const AboutGrid = styled(Grid)((props) => ({
  paddingTop: props.responsive.isDesktopUp ? 0 : '2.419rem',
}));

const MediumCopy = styled.p((props) => {
  if (props.responsive.isDesktopUp) {
    return {
      fontSize: '2rem',
      lineHeight: 1.25,
      color: props.theme.text,
      padding: '2.688rem 2.375rem 3.063rem 0',
      borderRight: `solid 1px ${props.theme.lines}`,
    };
  }
  return {
    fontSize: '1.25rem',
    lineHeight: 1.4,
    color: props.theme.text,
  };
});

const SmallCopy = styled.p((props) => {
  if (props.responsive.isDesktopUp) {
    return {
      fontSize: '0.875rem',
      lineHeight: 1.57,
      color: props.theme.text,
      maxWidth: '15.875rem',
      paddingTop: '2.688rem',
      paddingLeft: '2.375rem',
    };
  }
  return {
    fontSize: '0.875rem',
    lineHeight: 1.57,
    color: props.theme.text,
  };
});

const About = ({ ...otherProps }) => {
  const intl = useIntl();
  const sliderWords = [
    intl.formatMessage({ id: 'business' }),
    intl.formatMessage({ id: 'project' }),
    intl.formatMessage({ id: 'dream' }),
  ];
  const responsive = useResponsive();
  const { theme } = useTheme();

  return (
    <Section className="about-section" {...otherProps}>
      <Container>
        <CopyContainer {...{ theme }}>
          <Copy className="about__copy" responsive={responsive} {...{ theme }}>
            {intl.formatMessage({ id: 'takeYour' })}{' '}
            <WordsSwitcher speed={500} words={sliderWords} />
            {`${intl.formatMessage({ id: 'nextLevel' })}.`}
          </Copy>

          <SVGWrapper color={theme.lines}>
            <IconsSVG />
          </SVGWrapper>
        </CopyContainer>

        <AboutGrid
          gap={responsive.isDesktopUp ? 0 : '1.375rem'}
          gridTemplateColumns={responsive.isDesktopUp ? '1fr 1fr' : '1fr'}
          responsive={responsive}
        >
          <MediumCopy responsive={responsive} {...{ theme }}>
            {intl.formatMessage({ id: 'mediumCopy' })}
          </MediumCopy>
          <SmallCopy responsive={responsive} {...{ theme }}>
            {intl.formatMessage({ id: 'smallCopy' })}
          </SmallCopy>
        </AboutGrid>
      </Container>
    </Section>
  );
};

export default About;
