import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import styled from '@emotion/styled';
import { Section, Container, Grid } from '../components';
import { ResponsiveContext } from '../providers/responsive';
import { ThemeContext } from '../providers/themes';

const ServicesHeading = styled.h3((props) => {
  const commonStyles = {
    color: props.theme.text,
  };

  if (props.responsive.isDesktopUp) {
    return {
      fontSize: '5rem',
      letterSpacing: 2.86,
      ...commonStyles,
    };
  }
  return { ...commonStyles };
});

const Service = styled.div((props) => ({
  fontSize: '1.25rem',
  paddingTop: '0.438rem',
  paddingBottom: '0.438rem',
  color: props.theme.text,
  borderBottom: `solid 1px ${props.theme.lines}`,

  '&:last-child': {
    borderBottom: 'none',
  },
}));

const StyledGrid = styled(Grid)((props) => {
  if (props.responsive.isDesktopUp) {
    return {
      padding: '0 3.975rem 3.25rem 3.188rem',
      borderBottom: `solid 1px ${props.theme.lines}`,

      '&:last-child': {
        borderBottom: 'none',
        paddingBottom: 0,
      },
    };
  }
  return {};
});

const ServicesBlock = ({ title, services = [] }) => (
  <ThemeContext.Consumer>
    {({ theme }) => (
      <ResponsiveContext.Consumer>
        {(responsive) => (
          <StyledGrid
            {...{ theme }}
            responsive={responsive}
            gridTemplateColumns={responsive.isDesktopUp ? '1fr 1fr' : '1fr'}
            gap={responsive.isDesktopUp ? '2.5rem' : '1.938rem'}
          >
            <ServicesHeading {...{ theme }} responsive={responsive}>
              {title}
            </ServicesHeading>
            <div className="services__wrapper">
              {services.map((service, index) => (
                <Service key={`service-${index}`} {...{ theme }}>
                  {service}
                </Service>
              ))}
            </div>
          </StyledGrid>
        )}
      </ResponsiveContext.Consumer>
    )}
  </ThemeContext.Consumer>
);

const Services = (props) => {
  const intl = useIntl();
  const itServices = [
    intl.formatMessage({ id: 'google_workspace' }),
    intl.formatMessage({ id: 'google_cloud' }),
    intl.formatMessage({ id: 'aws' }),
  ];
  const digitalServices = [
    intl.formatMessage({ id: 'websites' }),
    intl.formatMessage({ id: 'ecommerce' }),
    intl.formatMessage({ id: 'apps' }),
    intl.formatMessage({ id: 'fullStack' }),
    intl.formatMessage({ id: 'uiux' }),
  ];

  return (
    <ResponsiveContext.Consumer>
      {(responsive) => (
        <Section className="services-section" {...props}>
          <Container>
            <Grid gap="4rem">
              <ServicesBlock
                title={intl.formatMessage({ id: 'software' })}
                services={digitalServices}
              />
              <ServicesBlock
                title={intl.formatMessage({ id: 'cloud' })}
                services={itServices}
              />
            </Grid>
          </Container>
        </Section>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default Services;
